// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import EarnCredits from './pages/EarnCredits'; // 引入 EarnCredits 页面
import AIDetector from './pages/AIDetector';
import Login from './pages/Login';
import Register from './pages/Register';
import ChangePassword from './pages/ChangePassword';
import UpdateUser from './pages/Update_User';
import DetectionResult from './pages/DetectionResult';
import RewriteResult from './pages/RewriteResult';
import History from './pages/History';
import CreditHistory from './pages/CreditHistory';
import Recharge from './pages/Recharge';
import PrivacyPolicy from './pages/footer_pages/PrivacyPolicy';
import TermsOfUse from './pages/footer_pages/TermsOfUse';
import ContactSupport from './pages/ContactSupport';
import PaymentResult from './pages/PaymentResult';
import RefundPolicy from './pages/footer_pages/RefundPolicy';
import DownPage from './pages/DownPage';
import './App.css';

const isServiceDown = false; // 硬编码服务状态：true 表示进入维护模式，false 表示正常运行

const App = () => {
  const location = useLocation();
  const showFooter = ['/', '/privacy-policy', '/terms-of-use', '/refund-policy'].includes(location.pathname);

  return (
    <div id="root">
      {isServiceDown ? (
        <DownPage /> // 如果服务不可用，显示 DownPage
      ) : (
        <>
          <Navbar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/AI_detector" element={<AIDetector />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/change_password" element={<ChangePassword />} />
              <Route path="/update_user" element={<UpdateUser />} />
              <Route path="/detection_result" element={<DetectionResult />} />
              <Route path="/rewrite_result" element={<RewriteResult />} />
              <Route path="/history" element={<History />} />
              <Route path="/credit_history" element={<CreditHistory />} />
              <Route path="/recharge" element={<Recharge />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/contact_support" element={<ContactSupport />} />
              <Route path="/payment_result" element={<PaymentResult />} />
              <Route path="/earn_credits" element={<EarnCredits />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/down" element={<DownPage />} />
            </Routes>
          </div>
          {showFooter && <Footer />}
        </>
      )}
    </div>
  );
};


const AppWrapper = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
