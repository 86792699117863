import React from 'react';
import { useDocToQrCode } from '../components/DocToQrCode';

const DownPage = () => {
  const googleDocUrl = 'https://docs.google.com/document/d/1iSkYJI4JfZ8uzDFLXZUh0YsdBarPsATqRpgn1PM_xQA/export?format=txt';
  const qrCodeUrl = useDocToQrCode(googleDocUrl);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f4f8',
        textAlign: 'center',
        color: '#333',
        fontFamily: 'Arial, sans-serif',
        padding: '20px',
      }}
    >
      {/* GIF Animation */}
      <img
        src="/images/Downpage.gif"
        alt="Service Unavailable Animation"
        style={{ width: '800px', height: '400px', marginBottom: '20px' }}
      />

      {/* Spinner Animation */}
      <div
        style={{
          border: '12px solid #e0e0e0',
          borderRadius: '50%',
          borderTop: '12px solid #007bff',
          width: '100px',
          height: '100px',
          animation: 'spin 1.5s linear infinite',
          marginBottom: '30px',
        }}
      />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>

      {/* Content */}
      <h1 style={{ fontSize: '3rem', color: '#d9534f', marginBottom: '20px' }}>
        Service Unavailable
      </h1>
      <p style={{ fontSize: '1.5rem', marginBottom: '20px' }}>
        Our service is currently unavailable. We are working hard to restore it as soon as possible.
      </p>
      <p style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
        If you need immediate assistance, please contact us at:
      </p>
      <a
        href="mailto:support@nlphraser.com"
        style={{
          fontSize: '1.2rem',
          color: '#007bff',
          textDecoration: 'none',
          marginBottom: '30px',
        }}
      >
        support@nlphraser.com
      </a>

      {/* QR Code */}
      {qrCodeUrl && (
        <div
          style={{
            marginTop: '20px',
            width: '180px',
            height: '180px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
            padding: '10px',
          }}
        >
          <img src={qrCodeUrl} alt="QR Code" style={{ width: '100%', height: '100%' }} />
        </div>
      )}

      <p style={{ fontSize: '1rem', marginTop: '15px', color: '#6c757d' }}>
        Scan the QR code to join our feedback group.
      </p>
    </div>
  );
};

export default DownPage;
