// BlackFridayCountdown.js
import React, { useState, useEffect } from 'react';


const BlackFridayCountdown = ({ t, endDate }) => {
  const [countdown, setCountdown] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const timeLeft = endDate - now;
      if (timeLeft <= 0) {
        clearInterval(interval);
        setCountdown(t('event_ended'));
      } else {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60);
        const seconds = Math.floor((timeLeft / 1000) % 60);
        setCountdown(
          `${days} ${t('days')} ${hours} ${t('hours')} ${minutes} ${t('minutes')} ${seconds} ${t('seconds')}`
        );
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [t, endDate]);

  // 内联样式
  const styles = {
    container: {
      textAlign: 'center',
      margin: '20px 0',
    },
    title: {
      fontSize: '2rem',
      fontWeight: 'bold',
      color: '#000', // 黑色
    },
    text: {
      fontSize: '2rem',
      color: '#ff6347', // 番茄色
      animation: 'fadeInOut 2s ease-in-out infinite',
    },
    '@keyframes gradientAnimation': {
      '0%': { backgroundPosition: '0% 50%' },
      '50%': { backgroundPosition: '100% 50%' },
      '100%': { backgroundPosition: '0% 50%' },
    },
    '@keyframes fadeInOut': {
      '0%, 100%': { opacity: 1 },
      '50%': { opacity: 0.5 },
    },
  };

  // 将关键帧动画添加到文档的样式表中
  useEffect(() => {
    const styleSheet = document.styleSheets[0];
    const gradientAnimation = `
      @keyframes gradientAnimation {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }
    `;
    const fadeInOut = `
      @keyframes fadeInOut {
        0%, 100% { opacity: 1; }
        50% { opacity: 0.5; }
      }
    `;
    styleSheet.insertRule(gradientAnimation, styleSheet.cssRules.length);
    styleSheet.insertRule(fadeInOut, styleSheet.cssRules.length);
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>{t('black_friday_countdown')}</h2>
      <p style={styles.text}>{countdown}</p>
    </div>
  );
};

export default BlackFridayCountdown;
